<template>
  <div class="menu__sidenav container">
    <div class="pt-8 pb-5 flex-column align-items-center relative">
      <div class="absolute icon-button-container">
        <CaIconButton
          icon="chevron-left"
          size="small"
          type="ghost"
          aria-label="back"
          @click="emits('clearMenuSelected')"
        />
      </div>
      <div>
        <h2 class="font-bold">
          <a :href="menuSelected.path" :title="`Ir para ${menuSelected.title}`">
            {{ menuSelected.title }}
          </a>
        </h2>
      </div>
    </div>

    <!-- recursive menu -->
    <WsHeaderMobileMenuItems
      :items="menuSelected.children"
      :departaments="departaments"
    />

    <!-- menu logout -->
    <CaLink
      v-if="menuSelected.type === 'account'"
      class="logout"
      full-width
      no-padding
      icon="chevron-right"
      icon-placement="right"
      color="primary"
      @click="emits('handleMenu', menuSelected)"
    >
      <div class="flex align-items-center justify-content-center">
        <CaIcon
          name="logout"
          color="primary"
          class="mr-3"
        />
        <span @click="emits('handleLogout')">Logout</span>
      </div>
    </CaLink>
  </div>
</template>

<script setup>
defineProps({
  menuSelected: { type: Object, default: () => ({}) },
  items: { type: Array, default: () => [] },
  departaments: { type: Array, default: () => [] },
});

const emits = defineEmits(['clearMenuSelected', 'handleLogout', 'handleMenu']);
</script>

<style lang="scss" scoped>
.icon-button-container {
  left: 0;
  top: 34%;
}

.logout {
  margin: space(4) 0 space(6) 0;
  padding: 0 space(7);
}
</style>
