<template>
	<div class="carousel">
		<div class="carousel__container">
			<ul class="carousel__items">
				<li
					v-for="item in items"
					:key="item.title"
					class="carousel__item"
					@click="handleDispatchEvent(item)"
				>
					<img
						width="70"
						height="70"
						:src="item.image"
						:alt="item.title"
					>
					<span
						class="carousel__label"
						v-text="item.title"
					/>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
import { slugify } from '@petlove/frontend-utilities-utils';

export default {
	props: {
		items: {
			type: Array,
			required: true,
		},
	},

	methods: {
		handleDispatchEvent(item) {
			this.$gtm.dispatchEvent({
				event_category: 'click',
				event_action: `menu:destaque:${slugify(item.title)}`,
			});

			window.open(item.path, '_self');
		},
	},
};
</script>

<style lang="scss" scoped>
.carousel {
  $this: &;
  &__container {
    margin: space(7) 0 space(7) space(5);
    #{$this}__items {
      display: flex;
      text-align: center;
      scrollbar-width: none;
      overflow-x: scroll;
      -webkit-overflow-scrolling: touch;
      overflow-y: hidden;
      gap: space(8);
      &::-webkit-scrollbar {
        display: none;
      }
      #{$this}__link {
        img {
          pointer-events: none;
          width: 72px;
          height: 72px;
          margin-bottom: space(3);
        }
      }
      #{$this}__item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
      }
      #{$this}__item:first-child {
        padding-left: 0;
      }
      #{$this}__item:last-child {
        padding-right: space(8);
      }
      #{$this}__label {
        font-weight: fontWeight(medium);
        font-size: fontSize(body-xs);
        color: color(primary);
        margin-top: space(3);
        line-height: space(4);
        line-break: auto;
        width: 70px;
      }
    }
  }
}
</style>
