<template>
  <div class="menu">
    <TransitionGroup name="slide-group" tag="div" class="menu-container" :class="transitionDirection">
      <ul key="main-menu" class="menu__items">
        <li
          v-for="(item, index) in items"
          :key="`${item.title}-${index}`"
        >
          <CaLink
            v-show="!item.showcase"
            class="my-4 mb-4"
            full-width
            no-padding
            icon="chevron-right"
            color="primary"
            icon-placement="right"
            @click="handleMenu(item)"
          >
            <div class="flex align-items-center justify-content-center">
              <CaIcon
                v-if="item.icon"
                :name="item.icon.toLowerCase()"
                color="primary"
                class="mr-3"
              />
              <span>{{ item.title }}</span>
              <CaTag
                v-if="item.tag"
                color="primary"
                class="ml-4"
              >
                {{ item.tag }}
              </CaTag>
            </div>
          </CaLink>

          <!-- root menu -->
          <div v-show="item.showcase && item.children.length">
            <span class="menu__items__title">{{ item.title }}</span>
            <ul class="my-4">
              <li
                v-for="(children, childrenIndex) in item.children"
                :key="`${children.title}-${childrenIndex}`"
                class="mb-4"
                @click="handleMenu(children)"
              >
                <CaLink
                  full-width
                  no-padding
                  icon="chevron-right"
                  color="primary"
                  icon-placement="right"
                >
                  <div class="flex align-items-center justify-content-center">
                    <CaIcon
                      v-if="children.icon"
                      :name="children.icon.toLowerCase()"
                      color="primary"
                      class="mr-3"
                    />
                    <span>{{ children.title }}</span>
                    <CaTag
                      v-if="children.tag"
                      color="primary"
                      class="ml-4"
                    >
                      {{ children.tag }}
                    </CaTag>
                  </div>
                </CaLink>

                <HeaderMobileMenuSubItems
                  style="visibility: hidden;"
                  :menu-selected="children"
                  :items="items"
                  :departaments="departaments"
                />
              </li>
            </ul>
          </div>
        </li>
      </ul>

      <HeaderMobileMenuSubItems
        v-if="menuSelected"
        :menu-selected="menuSelected"
        :items="items"
        :departaments="departaments"
        @handle-menu="handleMenu"
        @handle-logout="handleLogout"
        @clear-menu-selected="clearMenuSelected"
      />
    </TransitionGroup>
  </div>
</template>

<script setup>
import { slugify } from '@petlove/frontend-utilities-utils';
import { useAuthStore } from '@petlove/lion/store/auth';
import HeaderMobileMenuSubItems from './subItems/HeaderMobileMenuSubItems.vue';

const { logout } = useAuthStore();

const { $gtm } = useNuxtApp()


const props = defineProps({
  items: {
    type: Array,
    default: () => [],
  },
  departaments: {
    type: Array,
    default: () => [],
  },
  account: {
    type: Object,
    default: () => ({}),
  },
});

const transitionDirection = ref('forward');
const menuSelected = ref(null);

watch(
  () => props.account,
  (newValue) => {
    handleMenu(newValue.value);
  },
  { deep: true }
);

const handleMenu = (item) => {
  transitionDirection.value = 'forward';
  sendEvents(item);

  if (!item?.children?.length) return window.open(item.path, '_self');

  menuSelected.value = item;
};

const sendEvents = (item) => {
  const getMenuFirstLevel = () => sessionStorage.getItem('menu:first:level');
  const slugifyTitle = (title) => slugify(title);

  const dispatchGtmEventForItem = (params) => {
    $gtm.dispatchEvent({
      event_category: 'click',
      event_action: `menu:item:${slugifyTitle(item.title)}`,
      event_label: params.label,
    });
  };

  const processItem = (params) => {
    params.section = getMenuFirstLevel();
    dispatchGtmEventForItem(params);
  };

  props.items.forEach((section) => {
    if (section.type === 'others') {
      section.children.forEach((childrenSection) => {
        if (childrenSection.title === item.title) {
          processItem({
            label: slugifyTitle(section.title),
          });

          sessionStorage.setItem('menu:first:level', getMenuFirstLevel());
        }
      });
    }
  });

  if (item.type === 'category' || item.type === 'subcategory') {
    const params = {
      section: null,
      category: null,
      subCategory: null,
      departament: null,
    };

    props.departaments.forEach((departament) => {
      departament.children.forEach((childrenDepartament) => {
        childrenDepartament.children.forEach((category) => {
          if (
            (item.type === 'category' && category.children.includes(item)) ||
            (item.type === 'subcategory' &&
              category.children.some((subCategory) =>
                subCategory.children.includes(item)
              ))
          ) {
            params.category = slugifyTitle(category.title);
            params.departament = slugifyTitle(childrenDepartament.title);

            if (item.type === 'subcategory') {
              category.children.forEach((subCategory) => {
                if (subCategory.children.includes(item)) {
                  params.subCategory = slugifyTitle(subCategory.title);
                  processItem({
                    label: `${params.section}:${params.departament}:${params.category}:${params.subCategory}`,
                  });
                }
              });
            } else {
              processItem({
                label: `${params.section}:${params.departament}:${params.category}`,
              });
            }
          }
        });
      });
    });
  }
};

const clearMenuSelected = () => {
  transitionDirection.value = 'backward';
  menuSelected.value = null;
};

const handleLogout = () => {
  clearMenuSelected();
  logout();
};
</script>

<style lang="scss" scoped>
.menu {
  &__items {
    text-align: initial;
    margin: space(6) space(7) 0 space(7);

    &__title {
      color: color(neutral, darkest);
      font-size: fontSize(title-xs);
      font-weight: fontWeight(bold);
      margin: space(6) 0;
      display: block;
    }

    &--logout {
      margin: space(4) 0 space(6) 0;
      padding: 0 space(7);
    }
  }

  &__sidenav {
    background-color: color(neutral, lightest);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    min-height: 100vh;
    z-index: 4;
    overflow: scroll;
  }
}

.icon-filter-container {
  right: 0;
  top: 45%;
}

.slide-group-forward-enter-active,
.slide-group-forward-leave-active {
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
}

.slide-group-forward-enter-from {
  transform: translateX(100%);
}

.slide-group-forward-leave-to {
  transform: translateX(-30%);
  opacity: 0;
}

.slide-group-backward-enter-active,
.slide-group-backward-leave-active {
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
}

.slide-group-backward-enter-from {
  transform: translateX(-30%);
  opacity: 0;
}

.slide-group-backward-leave-to {
  transform: translateX(100%);
}

/* Classes baseadas na direção */
.forward .slide-group-enter-active {
  animation: slide-in-forward 0.3s;
}

.forward .slide-group-leave-active {
  animation: slide-out-forward 0.3s;
}

.backward .slide-group-enter-active {
  animation: slide-in-backward 0.3s;
}

.backward .slide-group-leave-active {
  animation: slide-out-backward 0.3s;
}

@keyframes slide-in-forward {
  from { transform: translateX(100%); }
  to { transform: translateX(0); }
}

@keyframes slide-out-forward {
  from { transform: translateX(0); }
  to { transform: translateX(-30%); opacity: 0; }
}

@keyframes slide-in-backward {
  from { transform: translateX(-30%); opacity: 0; }
  to { transform: translateX(0); opacity: 1; }
}

@keyframes slide-out-backward {
  from { transform: translateX(0); }
  to { transform: translateX(100%); }
}
</style>
